export default {
  state: {
    visibility: null,
    recording: null
  },
  mutations: {
    INIT_MIC(state, { visibility, recording }) {
      state.visibility = visibility
      state.recording = recording
    },
    SET_MIC_RECORDING(state, payload) {
      state.recording = payload
    }
  }
}
