const startBtnDialog = {
  state: {
    visibility: true
  },
  mutations: {
    SET_START_BUTTON_DIALOG(state, payload) {
      state.visibility = payload
    }
  }
}

export default startBtnDialog
