const loadingDialog = {
  state: {
    visibility: false
  },
  mutations: {
    SET_LOADING_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  }
}

export default loadingDialog
