<template>
  <v-fade-transition mode="out-in">
    <v-card v-show="visibility" class="buttonMenuContainer" flat color="transparent">
      <v-row no-gutters justify="center">
        <!-- Back Button -->
        <button v-if="parentButton" class="button backButton" @click.prevent="buttonClickHandler(parentButton.value)">
          <span class="buttonText backButtonText text-h4 pa-0">
            <v-icon left dark large class="mt-n4">fas fa-reply</v-icon>
            {{ parentButton.title }}
          </span>
        </button>

        <!-- Service Button -->
        <button class="button menuButton" @click.prevent="buttonClickHandler(button.value)" v-for="button in buttonMenu" :key="button.value">
          <span class="buttonText menuButtonText text-h4" :class="{ 'text-h5': current_lang === 'en' }"> {{ button.title }} </span>
        </button>
      </v-row>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'ButtonMenuCard',
  methods: {
    ...mapActions(['sendMessageToSocket']),
    ...mapMutations(['SET_LOADING_DIALOG_VISIBILITY']),
    buttonClickHandler(message) {
      this.SET_LOADING_DIALOG_VISIBILITY(true) // display the loading dialog. It will be dismissed automatically when the NLP result received.
      this.sendMessageToSocket({ event: 'get_voicebot_response', message: message })
      setTimeout(() => {
        if (this.loadingDialog.visibility) this.SET_LOADING_DIALOG_VISIBILITY(false)
      }, 5000)
    }
  },
  computed: {
    ...mapState(['i18n', 'socket', 'buttonMenuCard', 'loadingDialog']),
    current_lang() {
      return this.i18n.current_lang
    },
    visibility() {
      return this.buttonMenuCard.visibility
    },
    parentButton() {
      const nlp_result = this.socket.nlp.result
      if (nlp_result.buttonMenu?.parent && Object.keys(nlp_result.buttonMenu?.parent).length) {
        return nlp_result.buttonMenu.parent
      }
      return false
    },
    buttonMenu() {
      const nlp_result = this.socket.nlp.result
      if (nlp_result.buttonMenu?.buttons) return nlp_result.buttonMenu.buttons
      return false
    }
  }
}
</script>

<style scoped>
.buttonMenuContainer {
  position: absolute;
  width: 35vw;
  height: 50vh;
  right: 5vw;
  bottom: 8vh;
}

.button {
  margin-bottom: 2.5vh;
  width: 30vw;
  height: 5.5vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  outline: none;
}

.buttonText {
  font-weight: 800;
  display: block;
  width: 30vw;
  padding: 0 5vw;
  word-break: keep-all;
  text-align: center;
}

.backButton {
  background: linear-gradient(180deg, rgba(211, 17, 69, 0.8) 0%, rgba(211, 17, 69, 0) 100%), rgba(211, 17, 69, 0.5);
}

.backButtonText {
  color: #fff;
}

.menuButton {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.5);
}

.menuButtonText {
  color: #d31145;
}
</style>
