<template>
  <v-fade-transition mode="out-in">
    <v-dialog content-class="startBtnDialog" v-model="startBtnDialog.visibility" overlay-opacity="0.7" persistent max-width="25vw">
      <v-img contain :src="require('@/assets/images/buttons/start.svg')" @click="start"></v-img>
    </v-dialog>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StartBtnDialog',
  computed: { ...mapState(['startBtnDialog']) },
  methods: {
    start() {
      this.$store.dispatch('updateSystemMode') // Default Mode -> Facial Recognition
      this.$store.commit('SET_START_BUTTON_DIALOG', false)
    }
  }
}
</script>

<style>
@media only screen and (min-width: 320px) {
  .startBtnDialog {
    max-width: 60vw !important;
  }
}

@media only screen and (min-width: 520px) {
  .startBtnDialog {
    max-width: 50vw !important;
  }
}

@media only screen and (min-width: 720px) {
  .startBtnDialog {
    max-width: 30vw !important;
  }
}
</style>
