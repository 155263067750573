import { zhHant } from 'vuetify/es5/locale/zh-Hant'

// Usage -> {{ $vuetify.lang.t('$vuetify.close') }} -> 关闭
export default {
  ...zhHant,
  __topBar: {
    refresh: '刷新'
  },
  __TicketCard: {
    getTicket: '請取票'
  }
}
