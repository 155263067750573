import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import lang from '@/lang'

// i18n refs - https://vuetifyjs.com/en/features/internationalization/
// Change Lang Example -> in component: mapMutations -> in method: this.SET_I18N_CURRENT_LANGUAGE('YOUR LANGUAGE CODE')
// Check Lang Example -> in component: console.log(this.$vuetify.lang.current)
export default new Vuetify({
  lang: {
    locales: { zhHant: lang.zhHant, zhHans: lang.zhHans, en: lang.en },
    current: 'zhHant'
  },
  icons: {
    iconfont: 'mdi' || 'fa'
  }
})
