export default {
  state: {
    visibility: false,
    max_number: 4
  },
  mutations: {
    SET_NUM_CARD(state, { visibility, max_number = 4 }) {
      state.visibility = visibility
      state.max_number = max_number
    }
  }
}
