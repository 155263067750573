import router from '@/router'
import socketClient from 'socket.io-client'

const socket = {
  state: {
    session_id: null,
    voicebot_server: null,
    asr: {
      result: '',
      update_time: ''
    },
    nlp: {
      result: '',
      update_time: ''
    }
  },
  mutations: {
    DISCONNECT_SOCKET_VOICEBOT_CONNECTION(state) {
      if (state.voicebot_server) state.voicebot_server.disconnect()
    },
    SET_SOCKET_SESSION_ID(state, payload) {
      state.session_id = payload
    },
    UPDATE_SOCKET_ASR_RESULT(state, payload) {
      state.asr.result = payload
      state.asr.update_time = new Date()
    },
    UPDATE_SOCKET_NLP_RESULT(state, payload) {
      state.nlp.result = payload
      state.nlp.update_time = new Date()
    },
    // Update socket connection base on language selected
    SET_SOCKET_VOICEBOT_SERVER(state, payload) {
      // Establish socket-client instance & assign event listener
      state.voicebot_server = payload.socket_server
      state.voicebot_server.emit('register', {
        socket_session_id: state.session_id,
        language: payload.language
      })
      // Add event listener
      state.voicebot_server.on('asr_result', data => {
        this.dispatch('voicebot_socket_asr_result', data)
      })
      state.voicebot_server.on('tts_result', data => {
        this.dispatch('voicebot_socket_tts_result', data)
      })
    }
  },
  actions: {
    clearScreen({ commit }) {
      commit('UPDATE_SOCKET_NLP_RESULT', '')
      commit('SET_MESSAGE_CARD_VISIBILITY', false)
      commit('SET_BUTTON_MENU_CARD_VISIBILITY', false)
      commit('SET_NUM_CARD', { visibility: false })
      commit('SET_TICKET_CARD_VISIBILITY', false)
    },
    updateVoicebotSocketServer({ commit, rootState, dispatch }) {
      dispatch('clearScreen')
      commit('SET_LOADING_DIALOG_VISIBILITY', true)

      // Disconnect from the previous server before connecting to new server
      commit('DISCONNECT_SOCKET_VOICEBOT_CONNECTION')

      // Get the URL of the new socket server
      let voicebot_server = socketClient(process.env.VUE_APP_VOICEBOT_SOCKET_SERVER)
      commit('SET_SOCKET_VOICEBOT_SERVER', { socket_server: voicebot_server, language: rootState.i18n.socket_lang })
      setTimeout(() => {
        commit('SET_LOADING_DIALOG_VISIBILITY', false)

        // If the start button has been clicked
        if (!rootState.startBtnDialog.visibility) {
          commit('UPDATE_SOCKET_NLP_RESULT', '')
          commit('STOP_AVATAR_AUDIO')

          if (router.history.current.path === '/menu') {
            dispatch('sendMessageToSocket', { event: 'get_voicebot_response', message: process.env.VUE_APP_GREETING_INTENT })
          } else {
            dispatch('stopMonitoringFirebase')
            dispatch('startMonitoringFirebase')
          }
        }
      }, 800)
    },
    voicebot_socket_asr_result({ commit }, payload) {
      console.log('ASR Result: ', payload)
      const ignore_list = [process.env.VUE_APP_GREETING_INTENT, process.env.VUE_APP_FACIAL_REG_GREETING_INTENT]
      commit('UPDATE_SOCKET_ASR_RESULT', !ignore_list.includes(payload.text) ? payload.text : '')
    },
    voicebot_socket_tts_result({ commit, dispatch, rootState }, payload) {
      console.log('TTS Result: ', payload)

      // Control the visibility of the Message Card & Button Menu Card
      commit('SET_MESSAGE_CARD_VISIBILITY', payload.is_card_show)
      commit('SET_BUTTON_MENU_CARD_VISIBILITY', payload.is_menu_show)
      commit('SET_TICKET_CARD_VISIBILITY', payload.ambassador?.aia?.is_ticket_card_show || false)

      if (payload.ambassador?.aia?.is_num_card_show) {
        const max_number = payload.ambassador?.aia?.max_number || 4
        commit('SET_NUM_CARD', { visibility: true, max_number })
      } else commit('SET_NUM_CARD', { visibility: false })

      if (payload?.ambassador?.aia?.exchange_rate) commit('SET_EXCHANGE_RATE', payload.ambassador.aia.exchange_rate)

      // Update the NLP & TTS result to store
      const NLP_result = {
        message: payload.messages,
        buttonMenu: payload.menu,
        ticketInfo: payload.ambassador?.aia?.ticket_card
      }

      setTimeout(() => commit('UPDATE_SOCKET_NLP_RESULT', NLP_result), 100)

      // Set the visibility of the loading dialog to false
      commit('SET_LOADING_DIALOG_VISIBILITY', false)

      // Trigger avatar movement & sound
      const avatar_type = process.env.VUE_APP_AVATAR_TYPE
      const { audio_url, ambassador } = payload
      if (avatar_type === 'Animation') dispatch('playAnimation', audio_url)
      else if (avatar_type === 'Video' && ambassador?.avatar?.video) dispatch('playVideo', { audio_source: audio_url, video: ambassador.avatar.video })

      // Turn off mic recording temporary until the end of the audio for Facial Mode
      const audio_length = payload.audio_length
      if (audio_length && rootState.modeController.mode === 'facial') {
        commit('SET_MIC_RECORDING', false)
        setTimeout(() => {
          if (rootState.modeController.mode === 'facial' && rootState.modeController.facial_rec_data) commit('SET_MIC_RECORDING', true)
        }, audio_length * 1000 + 500)
      }
    },
    // Send message to Voicebot Socket
    sendMessageToSocket({ state, commit }, payload) {
      commit('SET_MIC_RECORDING', false)
      state.voicebot_server.emit(payload.event, payload.message)
    }
  }
}

export default socket
