<template>
  <v-app>
    <v-main>
      <Avatar />
      <LoadingDialog />
      <StartBtnDialog />
      <v-card height="100vh" width="100vw" style="position: absolute" color="transparent">
        <QRCodeCard />
        <Datetime />
        <Mic />
        <LeftBar />
        <MessageCard />
        <ButtonMenuCard />
        <NumCard />
        <TicketCard />
        <BottomBar />
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import Avatar from '@/components/Avatar'
import LoadingDialog from '@/components/Dialogs/LoadingDialog'
import StartBtnDialog from '@/components/Dialogs/StartBtnDialog'
import LeftBar from '@/components/Bars/LeftBar'
import BottomBar from '@/components/Bars/BottomBar'
import Mic from '@/components/Widgets/Mic'
import MessageCard from '@/components/Cards/MessageCard'
import ButtonMenuCard from '@/components/Cards/ButtonMenuCard'
import NumCard from '@/components/Cards/NumCard'
import TicketCard from '@/components/Cards/TicketCard'
import Datetime from '@/components/Widgets/Datetime'
import QRCodeCard from '@/components/Cards/QRCodeCard'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: { Avatar, LoadingDialog, StartBtnDialog, LeftBar, BottomBar, Mic, MessageCard, ButtonMenuCard, NumCard, TicketCard, Datetime, QRCodeCard },
  data() {
    return {
      isDisconnected: false,
      environment: null,
      timer: null
    }
  },
  computed: {
    ...mapState(['i18n', 'socket']),
    asr() {
      return this.socket.asr
    }
  },
  methods: {
    ...mapActions(['updateVoicebotSocketServer']),
    disconnectionDetector() {
      const interval = setInterval(() => {
        if (this.socket.voicebot_server.disconnected) {
          clearInterval(interval)
          console.log('Disconnected from socket server')
          this.isDisconnected = this.socket.voicebot_server.disconnected
          this.autoRegistration()
        }
      }, 1000)
    },
    autoRegistration() {
      const interval = setInterval(() => {
        console.log('Waiting for socket server restart...')
        if (this.socket.voicebot_server.connected) {
          clearInterval(interval)
          console.log('Connected to Socket Server. Successfully registered.')
          this.isDisconnected = this.socket.voicebot_server.disconnected
          this.socket.voicebot_server.emit('register', {
            socket_session_id: this.socket.session_id,
            language: this.i18n.socket_lang
          })
          this.disconnectionDetector()
        }
      }, 1000)
    }
  },
  mounted() {
    this.environment = this.$environment
    this.disconnectionDetector()

    // Idle Timer
    document.addEventListener('mousedown', () => {
      let remaining = 15
      clearInterval(this.timer)
      this.timer = null

      if (this.$route.path === '/menu') {
        this.timer = setInterval(() => {
          if (remaining > 0) {
            remaining -= 1
            console.log(`idleTimer: Remaining Time - ${remaining} secs`)
          } else {
            clearInterval(this.timer)
            console.log(`idleTimer: End of Timeout Timer`)

            // Restart Ambassador
            if (this.asr.result !== '' && this.$route.path === '/menu') {
              this.updateVoicebotSocketServer()
            }
          }
        }, 1000)
      }
    })
  }
}
</script>

<style>
* {
  touch-action: pan-x pan-y !important;
  font-family: PingFangHK-Semibold, sans-serif;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
</style>
