<template>
  <v-card class="qrCardContainer" flat color="blue" rounded="lg">
    <v-img :src="require('@/assets/images/QrCode.svg')" />
  </v-card>
</template>

<script>
export default {
  name: 'QRCodeCard'
}
</script>

<style scoped>
.qrCardContainer {
  position: absolute;
  width: 12.5rem;
  top: 34rem;
  left: 2.5rem;
}
</style>
