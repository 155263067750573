<template>
  <v-fade-transition mode="out-in">
    <v-dialog content-class="loadingDialog" v-model="loadingDialog.visibility" persistent max-width="25vw">
      <v-card color="rgb(0, 0, 0, 0.7)" rounded="xl" class="py-16" elevation="24">
        <v-container>
          <v-row>
            <v-col cols="12" style="text-align: center">
              <span class="loadingTitle text-sm-body-1 text-md-h5">Loading...</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="text-align: center">
              <v-progress-circular :indeterminate="true" :rotate="0" size="50" :width="6" color="grey"></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'LoadingDialog',
  computed: { ...mapState(['loadingDialog']) }
}
</script>

<style>
.loadingDialog {
  min-width: 180px !important;
}

.loadingTitle {
  font-weight: bold;
  color: whitesmoke;
}
</style>
