<template>
  <v-fade-transition mode="out-in">
    <v-card v-show="visibility" color="transparent" flat>
      <v-row justify="center">
        <v-col cols="8">
          <v-alert outlined color="#D31145" rounded="xl" class="pa-10 mt-2">
            <v-row no-gutters justify="start" style="width: 58vw">
              <div class="text-h4 black--text text text-center">{{ text }}</div>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MessageCard',
  data() {
    return {
      text: ''
    }
  },
  computed: {
    ...mapState(['messageCard', 'socket']),
    visibility() {
      return this.messageCard.visibility
    },
    nlp_result() {
      return this.socket.nlp.update_time
    }
  },
  watch: {
    nlp_result() {
      this.text = this.socket.nlp.result !== '' && this.socket.nlp.result.message.length ? this.socket.nlp.result.message[0].text : ''
    }
  }
}
</script>

<style scoped>
.text {
  width: 100%;
}

>>> .v-alert--outlined {
  border: 5px solid currentColor !important;
}
</style>
