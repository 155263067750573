// Import Vue core modules
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Import libraries modules
import vuetify from './plugins/vuetify'
import recorder from 'recorder-core'
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
import uid from 'uid'
import date from 'date-and-time'

// Modules init
Vue.config.productionTip = false
recorder.TrafficImgUrl = ''
Object.defineProperty(Vue.prototype, '$recorder', { value: recorder })
Object.defineProperty(Vue.prototype, '$date', { value: date })

// Generating Session ID For Socket Register
const projectName = process.env.VUE_APP_PROJECT_NAME
const accessPoint = router.history._startLocation // /?device=123
let deviceName = accessPoint.substring(accessPoint.indexOf('=') + 1) // 123
let environment = 'launched'
if (deviceName === '/' || deviceName === '/facial' || deviceName === '/menu') {
  // use sub-domain as query param "device" by default
  const subdomain = window.location.origin.split('.')[0].split('/')[2]
  deviceName = subdomain
  if (subdomain.includes('dev')) {
    environment = 'dev'
  }
}
store.commit('SET_SOCKET_SESSION_ID', `${projectName}-${deviceName}-${uid()}`) // Set Session ID
Object.defineProperty(Vue.prototype, '$environment', { value: environment })

// Voicebot Socket Init -> Default Cantonese, please check language code at the mutation function
store.dispatch('updateVoicebotSocketServer', 'zhHant')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
