import { en } from 'vuetify/es5/locale/en'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉
export default {
  ...en,
  __topBar: {
    refresh: 'Refresh'
  },
  __TicketCard: {
    getTicket: 'Please take the ticket'
  }
}
