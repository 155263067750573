<template>
  <v-fade-transition mode="out-in">
    <v-card v-show="visibility" class="ticketCardContainer" flat color="transparent">
      <v-row justify="center">
        <button class="button">
          <span class="buttonText text-h4"> {{ $vuetify.lang.t('$vuetify.__TicketCard.getTicket') }} </span>
        </button>
      </v-row>
      <div class="ticketInfo text-center">{{ ticket_code }}</div>
      <div class="ticketType text-center">{{ ticket_type }}</div>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TicketCard',
  methods: { ...mapActions(['sendMessageToSocket']) },
  computed: {
    ...mapState(['socket', 'ticketCard']),
    visibility() {
      return this.ticketCard.visibility
    },
    ticket_code() {
      const nlp_result = this.socket.nlp.result
      if (nlp_result.ticketInfo) return nlp_result.ticketInfo.code
      return ''
    },
    ticket_type() {
      const nlp_result = this.socket.nlp.result
      if (nlp_result.ticketInfo) return nlp_result.ticketInfo.service_type_name
      return ''
    }
  },
  watch: {
    // Back to Main Page After 2 Secs
    visibility() {
      if (this.visibility) {
        setTimeout(() => {
          this.sendMessageToSocket({ event: 'get_voicebot_response', message: process.env.VUE_APP_GREETING_INTENT })
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>
.ticketCardContainer {
  position: absolute;
  width: 35vw;
  height: 25vh;
  right: 5vw;
  bottom: 30vh;
}

.button {
  width: 30vw;
  height: 5.5vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  outline: none;
}

.buttonText {
  font-weight: 800;
  display: block;
  width: 30vw;
  padding: 0 5vw;
  word-break: keep-all;
  text-align: center;
  color: #d31145;
}

.ticketInfo {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: normal;
  font-size: 6vh;
  line-height: 6vh;
  color: #ffffff;
  margin: 4vh 0 1vh 0;
}

.ticketType {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: normal;
  font-size: 4vh;
  line-height: 4vh;
  color: #ffffff;
}
</style>
