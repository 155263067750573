<template>
  <v-card color="transparent" flat class="videoContainer">
    <!-- Idle Video -->
    <v-row :class="idle_video_visibility ? 'display-video' : 'invisible-video'" no-gutters class="fill-height" align="center">
      <video class="avatar" ref="idleVideoPlayer">
        <source :src="idle_video_src" type="video/webm" />
      </video>
    </v-row>

    <!-- Response Video 1 -->
    <v-row :class="response_video1_visibility ? 'display-video' : 'invisible-video'" no-gutters class="fill-height" align="center">
      <video class="avatar" ref="responseVideoPlayer1" />
    </v-row>

    <!-- Response Video 2 (for streaming text) -->
    <v-row :class="response_video2_visibility ? 'display-video' : 'invisible-video'" no-gutters class="fill-height" align="center">
      <video class="avatar" ref="responseVideoPlayer2" />
    </v-row>
  </v-card>
</template>

<script>
import 'video.js/dist/video-js.css'

import { mapState } from 'vuex'
export default {
  name: 'Video',
  computed: {
    ...mapState(['avatar']),
    idle_video_visibility() {
      return this.avatar.idle_video.visibility
    },
    response_video1_visibility() {
      return this.avatar.response_video1.visibility
    },
    response_video2_visibility() {
      return this.avatar.response_video2.visibility
    },
    idle_video_src() {
      return this.avatar.idle_video.src
    }
  },
  mounted() {
    this.$store.commit('INIT_AVATAR_VIDEO_PLAYER', { ref1: this.$refs.idleVideoPlayer, ref2: this.$refs.responseVideoPlayer1, ref3: this.$refs.responseVideoPlayer2 })
  }
}
</script>

<style scoped>
.videoContainer {
  overflow: hidden;
  height: inherit;
  width: inherit;
}

.avatar video {
  /* Make video to at least 100% wide and tall */
  min-width: inherit;
  min-height: inherit;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: 1400px;
  bottom: 0;
  left: 110px;
  position: absolute;
}

.display-video {
  animation: fade-in 0.2s ease-in-out forwards;
  z-index: 10;
}

.invisible-video {
  animation: fade-out 0.2s ease-in forwards;
  animation-delay: 0.1s;
  z-index: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
