import { zhHans } from 'vuetify/es5/locale/zh-Hans'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉
export default {
  ...zhHans,
  __topBar: {
    refresh: '刷新'
  },
  __TicketCard: {
    getTicket: '请取票'
  }
}
