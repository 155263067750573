<template>
  <v-fade-transition mode="out-in">
    <v-card v-show="visibility" class="numCardContainer" flat color="transparent">
      <v-row no-gutters justify="center">
        <v-img :src="numPadImage" width="100%" height="100%"></v-img>

        <span class="input" :class="{ 'input-8': max_number === 8, 'input-11': max_number === 11 }">{{ displayEnteredNum }}</span>

        <!-- BackSpace -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" height="3vh" style="top: 27%; right: 14%" @click="backspace"></v-btn>

        <!-- Submit -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="18vw" height="4vh" style="bottom: 12%; right: 13%" @click="submit"></v-btn>

        <!-- NumBoard -->
        <!-- 7 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 40%; left: 9%" @click="enterNum(7)"></v-btn>
        <!-- 8 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 40%; left: 36%" @click="enterNum(8)"></v-btn>
        <!-- 9 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 40%; right: 13%" @click="enterNum(9)"></v-btn>

        <!-- 4 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 52%; left: 9%" @click="enterNum(4)"></v-btn>
        <!-- 5 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 52%; left: 36%" @click="enterNum(5)"></v-btn>
        <!-- 6 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="top: 52%; right: 13%" @click="enterNum(6)"></v-btn>

        <!-- 1 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="bottom: 24%; left: 9%" @click="enterNum(1)"></v-btn>
        <!-- 2 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="bottom: 24%; left: 36%" @click="enterNum(2)"></v-btn>
        <!-- 3 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="bottom: 24%; right: 13%" @click="enterNum(3)"></v-btn>

        <!-- 0 -->
        <v-btn depressed :ripple="false" class="floatButton" color="transparent" width="8.5vw" height="4vh" style="bottom: 12%; left: 9%" @click="enterNum(0)"></v-btn>
      </v-row>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'NumCard',
  data() {
    return {
      enteredNum: []
    }
  },
  methods: {
    ...mapActions(['sendMessageToSocket']),
    ...mapMutations(['SET_LOADING_DIALOG_VISIBILITY']),
    backspace() {
      this.enteredNum.splice(-1, 1)
    },
    submit() {
      if (this.enteredNum.length === this.max_number) {
        this.SET_LOADING_DIALOG_VISIBILITY(true) // display the loading dialog. It will be dismissed automatically when the NLP result received.
        this.sendMessageToSocket({ event: 'get_voicebot_response', message: this.enteredNum.join('') })
        setTimeout(() => {
          if (this.loadingDialog.visibility) this.SET_LOADING_DIALOG_VISIBILITY(false)
        }, 5000)
      }
    },
    enterNum(number) {
      if (this.enteredNum.length < this.max_number) {
        this.enteredNum.push(number)
      }
    }
  },
  computed: {
    ...mapState(['i18n', 'numCard', 'loadingDialog']),
    current_lang() {
      return this.i18n.current_lang
    },
    numPadImage() {
      if (this.max_number === 8) {
        if (this.current_lang === 'zhHant') return require('@/assets/images/numCard-8.svg')
        if (this.current_lang === 'zhHans') return require('@/assets/images/numCard-8_SC.svg')
        else return require('@/assets/images/numCard-8_En.svg')
      } else if (this.max_number === 11) {
        if (this.current_lang === 'zhHant') return require('@/assets/images/numCard-11.svg')
        if (this.current_lang === 'zhHans') return require('@/assets/images/numCard-11_SC.svg')
        else return require('@/assets/images/numCard-11_En.svg')
      } else {
        if (this.current_lang === 'zhHant') return require('@/assets/images/numCard.svg')
        if (this.current_lang === 'zhHans') return require('@/assets/images/numCard_SC.svg')
        else return require('@/assets/images/numCard_En.svg')
      }
    },
    visibility() {
      return this.numCard.visibility
    },
    max_number() {
      return this.numCard.max_number
    },
    displayEnteredNum() {
      let enteredNum = [...this.enteredNum]
      if (enteredNum.length > 1) {
        for (let index = 0; index < enteredNum.length - 1; index++) {
          enteredNum[index] = '*'
        }
        return enteredNum.join('')
      } else {
        return enteredNum[0]
      }
    }
  },
  watch: {
    visibility() {
      if (this.visibility) {
        this.enteredNum = []
      }
    }
  }
}
</script>

<style scoped>
.numCardContainer {
  position: absolute;
  width: 35vw;
  height: 33vh;
  right: 3vw;
  bottom: 17vh;
}

.input {
  position: absolute;
  top: 30%;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 23px;
  letter-spacing: 7px;
  color: #4d4d4d;
}

.input-8 {
  font-size: 22px !important;
}

.input-11 {
  font-size: 16px !important;
}

.floatButton {
  position: absolute;
}

div >>> .v-btn::before {
  background-color: transparent;
}
</style>
