// Import Vue core modules
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue')
  },
  {
    path: '/facial',
    name: 'FacialRecMode',
    component: () => import(/* webpackChunkName: "FacialRecMode" */ '../views/FacialRecMode.vue')
  },
  {
    path: '/menu',
    name: 'MenuMode',
    component: () => import(/* webpackChunkName: "MenuMode" */ '../views/MenuMode.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Avoid redirect to same page
  if (to === from) {
    return
  }

  if (to.name === null) {
    return next('/')
  } else if (to.name === 'FacialRecMode' || to.name === 'MenuMode') {
    if (from.name !== 'Index' && from.name !== 'FacialRecMode' && from.name !== 'MenuMode') {
      return next('/')
    }
  }
  next()
})

export default router
