<template>
  <v-img class="mt-12" :src="getDateImage" height="11vh" contain></v-img>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Datetime',
  data() {
    return {
      time: '',
      period: '',
      date: '',
      en: require('date-and-time/locale/en'),
      tw: require('date-and-time/locale/zh-tw')
    }
  },
  computed: {
    ...mapState(['i18n']),
    getDateImage() {
      return (
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`<svg width="454" height="136" viewBox="0 0 454 136" fill="none" xmlns="http://www.w3.org/2000/svg">
<text fill="#020202" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="100" letter-spacing="0px"><tspan x="0" y="106.794">${this.time}</tspan></text>
<text fill="#020202" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="32" letter-spacing="0px"><tspan x="270" y="100">${this.period}</tspan></text>
<text fill="#020202" xml:space="preserve" style="white-space: pre" font-family="Open Sans" font-size="30" letter-spacing="0px"><tspan x="270" y="60">${this.date}</tspan></text>
</svg>`)
      )
    }
  },
  mounted() {
    setInterval(() => {
      const date = new Date()
      this.time = this.$date.format(date, 'hh:mm')
      this.period = this.$date.format(date, 'A')

      if (this.i18n.current_lang === 'en') {
        this.$date.locale(this.en)
        this.date = this.$date.format(date, 'MMM D (ddd)')
      } else {
        this.$date.locale(this.tw)
        this.date = this.$date.format(date, 'M月D日(dd)')
      }
    }, 1000)
  }
}
</script>

<style scoped></style>
