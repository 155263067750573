<template>
  <v-card flat color="transparent" class="avatar-container" :class="{ 'video-type': type === 'Video' }" :style="{ backgroundImage: 'url(' + background + ')' }">
    <v-fade-transition mode="out-in">
      <Animation v-if="type === 'Animation'" />
      <Video v-else-if="type === 'Video'" />
    </v-fade-transition>
  </v-card>
</template>

<script>
import Animation from './Animation'
import Video from './Video'

export default {
  name: 'Avatar',
  components: { Animation, Video },
  data() {
    return {
      background: require('@/assets/images/bg.png'),
      type: process.env.VUE_APP_AVATAR_TYPE
    }
  }
}
</script>

<style scoped>
.avatar-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  background-size: cover;
}
</style>
