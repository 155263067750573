import firebase from '@/services/firebase'
import router from '@/router'

export default {
  state: {
    mode: null,
    firebase: null,
    facial_rec_data: null
  },
  mutations: {
    SET_MODE_CONTROLLER_MODE(state, payload) {
      state.mode = payload
    },
    SET_MODE_CONTROLLER_FIREBASE(state, payload) {
      state.firebase = payload
    },
    SET_MODE_CONTROLLER_FACIAL_REC_DATA(state, payload) {
      state.facial_rec_data = payload
    }
  },
  actions: {
    updateSystemMode({ commit, dispatch, rootState }) {
      const avatar_type = process.env.VUE_APP_AVATAR_TYPE
      if (avatar_type === 'Animation') commit('STOP_AVATAR_AUDIO')

      dispatch('clearScreen')
      commit('SET_MESSAGE_CARD_VISIBILITY', false)
      const mode = rootState.modeController.mode === null ? 'mic' : rootState.modeController.mode === 'mic' ? 'facial' : 'mic'
      commit('SET_MODE_CONTROLLER_MODE', mode)
      if (mode === 'facial') {
        dispatch('startMonitoringFirebase')
        router.push({ path: '/facial' })
      } else {
        dispatch('stopMonitoringFirebase')
        commit('INIT_MIC', { visibility: false, recording: false })
        router.push({ path: '/menu' })
        dispatch('sendMessageToSocket', { event: 'get_voicebot_response', message: process.env.VUE_APP_GREETING_INTENT })
      }
    },
    startMonitoringFirebase({ commit, state, dispatch }) {
      // Set Firebase Listener
      commit(
        'SET_MODE_CONTROLLER_FIREBASE',
        firebase.on('value', snapshot => {
          const current_facial_rec_data = state.facial_rec_data
          let new_facial_rec_data = current_facial_rec_data

          if (!current_facial_rec_data) {
            if (snapshot.exists()) {
              snapshot.forEach(data => {
                const sensitivity = process.env.VUE_APP_GREETING_DISTANCE_SENSITIVITY
                const new_distance = data.val().distance
                const new_facial_id = data.ref.key

                if (sensitivity > new_distance) {
                  new_facial_rec_data = data.val()
                  new_facial_rec_data.id = new_facial_id
                  dispatch('sendMessageToSocket', { event: 'get_voicebot_response', message: process.env.VUE_APP_FACIAL_REG_GREETING_INTENT })

                  // Turn on the mic
                  commit('INIT_MIC', { visibility: true, recording: true })
                }
              })
            }
          } else {
            if (snapshot.exists()) {
              const id_list = Object.keys(snapshot.val())
              if (!id_list.includes(current_facial_rec_data.id)) {
                new_facial_rec_data = null
              } else {
                snapshot.forEach(data => {
                  const sensitivity = process.env.VUE_APP_GREETING_DISTANCE_SENSITIVITY
                  const new_distance = data.val().distance
                  const new_facial_id = data.ref.key

                  if (new_facial_id === current_facial_rec_data.id) {
                    if (new_distance > sensitivity) {
                      new_facial_rec_data = null
                    } else {
                      new_facial_rec_data = data.val()
                      new_facial_rec_data.id = new_facial_id
                    }
                  }
                })
              }
            } else {
              new_facial_rec_data = null
            }
          }

          commit('SET_MODE_CONTROLLER_FACIAL_REC_DATA', new_facial_rec_data)
          if (!new_facial_rec_data) {
            // Turn off the mic
            commit('UPDATE_SOCKET_NLP_RESULT', '')
            commit('INIT_MIC', { visibility: false, recording: false })
          }
        })
      )
    },
    stopMonitoringFirebase({ rootState, commit }) {
      firebase.off('value', rootState.modeController.firebase) // Turn off the listener
      commit('SET_MODE_CONTROLLER_FIREBASE', null)
      commit('SET_MODE_CONTROLLER_FACIAL_REC_DATA', null)
    }
  }
}
