<template>
  <v-img @click="changeLang" :src="getImage" contain width="15vw" class="py-2 langButton"></v-img>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'LangBtn',
  props: {
    lang: {
      type: String,
      default: 'zhHant',
      require: true
    }
  },
  methods: {
    ...mapMutations(['SET_I18N_CURRENT_LANGUAGE']),
    ...mapActions(['updateVoicebotSocketServer']),
    changeLang() {
      this.SET_I18N_CURRENT_LANGUAGE(this.lang)
      this.updateVoicebotSocketServer()
    },
    getButtonConfig() {
      switch (this.lang) {
        case 'zhHant':
          return this.current_lang === this.lang
            ? { title: '廣東話', text_color: '#FFFFFF', text_x_position: 12, text_y_position: 55, bg_color: '#FF754D' }
            : { title: '廣東話', text_color: '#333D47', text_x_position: 12, text_y_position: 55, bg_color: '#FFFFFF' }
        case 'zhHans':
          return this.current_lang === this.lang
            ? { title: '普通话', text_color: '#FFFFFF', text_x_position: 12, text_y_position: 55, bg_color: '#FF754D' }
            : { title: '普通話', text_color: '#333D47', text_x_position: 12, text_y_position: 55, bg_color: '#FFFFFF' }
        case 'en':
          return this.current_lang === this.lang
            ? { title: 'English', text_color: '#FFFFFF', text_x_position: 8, text_y_position: 55, bg_color: '#FF754D' }
            : { title: 'English', text_color: '#333D47', text_x_position: 8, text_y_position: 55, bg_color: '#FFFFFF' }
      }
    }
  },
  computed: {
    ...mapState(['i18n']),
    current_lang() {
      return this.i18n.current_lang
    },
    getImage() {
      return (
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="48" cy="44" r="44" fill="${this.getButtonConfig().bg_color}"/>
</g>
<text fill="${this.getButtonConfig().text_color}" xml:space="preserve" style="white-space: pre" font-family="PingFang SC" font-size="24" letter-spacing="0px"><tspan x="${
          this.getButtonConfig().text_x_position
        }" y="${this.getButtonConfig().text_y_position}">${this.getButtonConfig().title}</tspan></text>
<defs>
<filter id="filter0_d" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
`)
      )
    }
  }
}
</script>

<style scoped>
.langButton {
  cursor: pointer;
}
</style>
