export default {
  state: {
    datetime: null,
    rate: null
  },
  mutations: {
    SET_EXCHANGE_RATE(state, { datetime, rate }) {
      state.datetime = datetime
      state.rate = rate
    }
  }
}
