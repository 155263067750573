<template>
  <v-img style="position: absolute; bottom: 0" :src="getImage" contain width="100%"></v-img>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BottomBar',
  computed: {
    ...mapState(['i18n']),
    current_lang() {
      return this.i18n.current_lang
    },
    position() {
      return this.current_lang === 'en' ? 50 : 207.297
    },
    text() {
      if (this.current_lang === 'zhHant') return '請不要說出您的個人資料'
      else if (this.current_lang === 'zhHans') return '请不要说出您的个人资料'
      else return `Please don't disclose your personal information`
    },
    getImage() {
      return (
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`<svg width="720" height="52" viewBox="0 0 720 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<rect width="720" height="45" rx="10" fill="black" fill-opacity="0.5"/>
<rect width="720" height="45" rx="10" fill="url(#paint0_linear)" fill-opacity="0.8"/>
</g>
<text fill="white" xml:space="preserve" style="white-space: pre" font-family="PingFang TC" font-size="26" font-weight="600" letter-spacing="0px"><tspan x="${this.position}" y="33.36">*${this.text}</tspan></text>
<defs>
<filter id="filter0_d" x="-4" y="0" width="728" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="360" y1="0" x2="360" y2="45" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
`)
      )
    }
  }
}
</script>

<style scoped></style>
