import videojs from 'video.js'

const avatar = {
  state: {
    url: '',
    iframe: null,

    // Video
    audio_player: null,
    idle_video: {
      visibility: true,
      src: require('@/assets/videos/idle.webm'),
      player: null
    },
    response_video1: {
      visibility: false,
      src: null,
      player: null
    },
    response_video2: {
      visibility: false,
      src: null,
      player: null
    },

    is_playing: false // Whether any audio or video is playing
  },
  mutations: {
    // Animation
    SET_AVATAR_URL(state, payload) {
      state.url = payload
    },
    SET_AVATAR_IFRAME(state, payload) {
      state.iframe = payload
    },

    // Video
    INIT_AVATAR_VIDEO_PLAYER(state, { ref1, ref2, ref3 }) {
      const options = { autoplay: 'muted', errorDisplay: false, loadingSpinner: false }
      state.idle_video.player = videojs(ref1, { loop: true, ...options })
      state.response_video1.player = videojs(ref2, { loop: false, ...options })
      state.response_video2.player = videojs(ref3, { loop: false, ...options })
      state.idle_video.player.log.level('off')
      state.response_video1.player.log.level('off')
      state.response_video2.player.log.level('off')
    },

    // Controllers
    SET_AVATAR_IS_PLAYING(state, payload) {
      state.is_playing = payload
    },
    STOP_AVATAR_AUDIO(state) {
      const type = process.env.VUE_APP_AVATAR_TYPE
      if (type === 'Animation') state.iframe.contentWindow.postMessage({ type: 'stopSound' }, '*')
      else if (type === 'Video') {
        if (state.audio_player) state.audio_player.pause()
        if (!state.idle_video.visibility) {
          state.idle_video.player.currentTime(0)
          state.idle_video.visibility = true
          state.response_video1.visibility = false
          state.response_video2.visibility = false
        }
      }
    }
  },
  actions: {
    playAnimation({ state }, url) {
      const params = { type: 'playSound', url }
      state.iframe.contentWindow.postMessage(params, '*') // start the animate (once new motion & sound incoming, previous action will auto-stop)
    },
    playVideo({ state, commit }, { audio_source, video }) {
      console.log(audio_source, video)
      commit('SET_AVATAR_IS_PLAYING', true)

      const current_display_player = state.idle_video.visibility ? state.idle_video : state.response_video1.visibility ? state.response_video1 : state.response_video2
      const next_display_player = state.response_video1.visibility ? state.response_video2 : state.response_video1

      // Preload Audio
      state.audio_player = new Audio(audio_source)
      state.audio_player.oncanplay = () => {
        // Preload Video
        next_display_player.player.src({ type: 'video/webm', src: video?.video_source })
        console.log(next_display_player)
        next_display_player.player.on('loadeddata', () => {
          // Play audio
          state.audio_player.play()
          current_display_player.visibility = false
          next_display_player.visibility = true
          next_display_player.player.off('play') // Remove play event listener
        })
      }

      // Play Idle Video after response end
      state.audio_player.onended = () => {
        commit('SET_AVATAR_IS_PLAYING', false)
        state.idle_video.visibility = true
        state.idle_video.player.currentTime(0)

        state.response_video1.visibility = false
        state.response_video2.visibility = false
      }
    }
  }
}

export default avatar
