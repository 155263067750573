<template>
  <v-fade-transition mode="out-in">
    <v-card color="transparent" class="micCard" flat v-show="visibility">
      <v-img v-show="!recording" :src="require('@/assets/images/onePressMicIdle.svg')"></v-img>
      <v-card v-show="recording" flat class="animatedMic" color="transparent">
        <object style="pointer-events: none" type="image/svg+xml" :data="require('@/assets/images/onePressMicAnimation.svg')"></object>
      </v-card>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Mic',
  data() {
    return {
      rec: null,
      duration: null,
      powerLevel: null
    }
  },
  methods: {
    ...mapMutations(['SET_MIC_RECORDING']),
    // Start Recording & Send Audio data to Socket
    startRecording() {
      if (this.rec) {
        this.rec.start()
        this.voicebot.emit('recording')
        console.log('Start recording')
      } else {
        alert('Error: Your browser do not support stream recording')
      }
    },
    // Stop Recording & Emit End Recording signal to Socket
    stopRecording() {
      if (this.rec) {
        this.rec.stop(null, null, false)
        console.log('Stop recording')
        this.voicebot.emit('stop_recording')
      }
    }
  },
  computed: {
    ...mapState(['mic', 'socket']),
    voicebot() {
      return this.socket.voicebot_server
    },
    visibility() {
      return this.mic.visibility
    },
    recording() {
      return this.mic.recording
    }
  },
  watch: {
    // For Facial Recognition
    recording() {
      if (this.recording) {
        this.startRecording()
      } else {
        this.stopRecording()
      }
    }
  },
  created() {
    // Create recorder instance
    this.rec = this.$recorder({
      type: 'mp3',
      bitRate: 16,
      sampleRate: 16000,
      onProcess: (buffers, powerLevel, duration) => {
        this.duration = duration
        this.powerLevel = powerLevel
        this.voicebot.emit('write_record_data', buffers[buffers.length - 1].buffer)
      }
    })

    this.rec.open(
      () => {
        console.log('Recorder has been turned on')
      },
      fail => {
        alert(`Your browser do not support stream recording, please try to use another browser. ${fail}`)
      }
    )
  }
}
</script>

<style scoped>
.micCard {
  position: absolute !important;
  top: 30%;
  width: 18vw;
  height: 18vw;
  cursor: pointer;
  right: 0vw;
}

.animatedMic {
  cursor: pointer;
}
</style>
