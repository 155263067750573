<template>
  <v-card class="pl-6 leftBarContainer" color="transparent" flat>
    <ModeButton />
    <LangButton lang="zhHant" />
    <LangButton lang="en" />
    <LangButton lang="zhHans" />
  </v-card>
</template>

<script>
import LangButton from '@/components/Widgets/LangButton'
import ModeButton from '@/components/Widgets/ModeButton'

export default {
  name: 'LeftBar',
  components: { LangButton, ModeButton }
}
</script>

<style scoped>
.leftBarContainer {
  position: absolute;
  bottom: 5vh;
}
</style>
