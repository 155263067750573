import Vuetify from '@/plugins/vuetify'

const i18n = {
  state: {
    current_lang: Vuetify.framework.lang.current,
    socket_lang: 'cantonese' // Language Code for Socket Registration
  },
  mutations: {
    SET_I18N_CURRENT_LANGUAGE(state, payload) {
      Vuetify.framework.lang.current = payload
      state.current_lang = payload

      switch (payload) {
        case 'zhHant':
          state.socket_lang = 'cantonese'
          break
        case 'zhHans':
          state.socket_lang = 'mandarin'
          break
        case 'en':
          state.socket_lang = 'english'
          break
      }
    }
  }
}

export default i18n
