<template>
  <iframe ref="avatar" scrolling="no" allow="autoplay" class="iframe" :src="avatar.url">
    Your browser doesn't support AI Avatar
  </iframe>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Animation',
  computed: { ...mapState(['avatar']) },
  mounted() {
    this.$store.commit('SET_AVATAR_IFRAME', this.$refs.avatar)
  },
  created() {
    const base_url = process.env.VUE_APP_AI_AVATAR_URL
    const model = process.env.VUE_APP_MODEL_ID
    const x_position = process.env.VUE_APP_MODEL_X || window.innerWidth * -0.1
    const y_position = process.env.VUE_APP_MODEL_Y || window.innerHeight * -0.3
    const scale_ratio = process.env.VUE_APP_SCALE_RATIO || 1.25
    const access_token = process.env.VUE_APP_ACCESS_TOKEN
    const avatar_url = `${base_url}/avatar?model_id=${model}&x=${x_position}&y=${y_position}&scale=${scale_ratio}&access_token=${access_token}`

    this.$store.commit('SET_AVATAR_URL', avatar_url)
  }
}
</script>

<style scoped>
.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  background-size: cover;
}
</style>
