<template>
  <v-img :src="image" @click="$store.dispatch('updateSystemMode')" contain width="15vw" class="py-2 modeButton"></v-img>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ModeButton',
  computed: {
    ...mapState(['modeController']),
    mode() {
      return this.modeController.mode
    },
    image() {
      return this.mode === 'facial' ? require('@/assets/images/modeBtn_selected.svg') : require('@/assets/images/modeBtn_unselect.svg')
    }
  }
}
</script>

<style scoped>
.modeButton {
  cursor: pointer;
}
</style>
